import { Navigate, Outlet } from "react-router-dom";
import { useLocation } from "react-router";
import { useAuth } from "../context/auth/auth";
import React from "react";

type Props = {
  isPrivate?: boolean;
  children?: React.ReactNode;
  roles?: string[];
};

export const ProtectedRoute = ({ isPrivate, roles }: Props): any => {
  const { user } = useAuth();

  const location = useLocation();

  if (roles && !!user) {
    if (isPrivate && !roles.includes(user?.profile)) {
      return <Navigate to={"/not-access"} />;
    }
  }

  return isPrivate === !!user ? (
    <Outlet />
  ) : (
    <Navigate to={isPrivate ? "/auth" : "/"} replace state={{ location }} />
  );
};
