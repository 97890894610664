export function setLocalStorage(user: UserModel | null) {
  localStorage.setItem("@wa-sender", JSON.stringify(user));
}

export function getUserLocalStorage() {
  const user = localStorage.getItem("@wa-sender");
  if (!user) {
    return null;
  }
  return JSON.parse(user);
}

export function removeUserLocalStorage() {
  localStorage.removeItem("@wa-sender");
}
