import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Input, InputAdornment, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { AsideBar } from '../../../components/templates/AsideBar';
import { useAppLoading } from '../../../context/loading';
import { DeleteClient, loadClients } from '../../../services/companyService';
import { CustomButton } from '../../../components/atoms/Button';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { ModalAddClient } from '../../../components/organisms/ModalAddClient';
import { Link } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { GridSearchIcon } from '@mui/x-data-grid';

const schema = yup
  .object({
    name: yup.string().required(),
  })
  .required();

const Clients = () => {
  const { loading, setLoading } = useAppLoading();
  const [rows, setRows] = useState<IClientModel[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [busca, setBusca] = useState('')
  const [selectedClient, setSelectedClient] = useState<
  IClientModel | undefined
  >(undefined);
  
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [clientDelete, setClientDelete] = useState<IClientModel | null>(null);

  const { control, handleSubmit, reset } = useForm<CreateUserModel>({
    resolver: yupResolver(schema),
  });

  const handleClickOpenDelete = (deleteId: any) => {
    setClientDelete(deleteId);
    setOpenDeleteModal(true);
  };

  const handleCloseDelete = () => {
    setClientDelete(null);
    setOpenDeleteModal(false);
  };

  const handleDelete = () => {
    setLoading(true);
    if (clientDelete) {
      DeleteClient(clientDelete._id)
        .then((res: any) => {
          toast.warn(res.msg);
          handleCloseDelete()
          load();
        })
        .catch((res: any) => {
          if (res?.response?.data?.msg) {
            toast.warn(res.response.data.msg);
          } else {
            toast.error('Erro ao processar requisição.');
          }
        })
        .finally(() => {
          setLoading(false);
        });

    }
  };

  const load = () => {
    setLoading(true);
    loadClients(busca)
      .then((res) => {
        const translated = res.map((item: any) => {
          item.id = item._id;
          return item;
        });
        setRows(translated);
      })
      .finally(() => {
        setLoading(false);
      });

  };

  useEffect(() => {
    load();

  }, [busca]);

  const handleBuscar = (e: any) => {
    e.preventDefault()
    setBusca(e.target.value)
  }


  const handleToggleModal = () => {
    setSelectedClient(undefined);
    return setOpenModal((prevState) => !prevState);
  };

  const handleToggleEditModal = (row: any) => {
    setSelectedClient(row);
    return setOpenModal((prevState) => !prevState);
  };

  const onSubmit = () => {
    setLoading(true);

  }

  return (
    <AsideBar>
      <>
        <header>
          <Box display="flex" flexDirection="row" justifyContent="space-between" mb={1}>
            <Box>
              <Typography variant="h6" component="h6">
                Clientes
              </Typography>
            </Box>
            <Box>
              <CustomButton variant={'contained'} onClick={handleToggleModal}>
                Novo cliente
              </CustomButton>
            </Box>

          </Box>

          <Input
            placeholder='Buscar cliente'
            value={busca}
            onChange={handleBuscar}
            fullWidth
            sx={{ mb: 4, mt: 2 }}
            id="input-with-icon-adornment"
            startAdornment={
              <InputAdornment position="start">
                <GridSearchIcon />
              </InputAdornment>
            }
          />

        </header>
        {/* <div>
          <Box display="flex" flexDirection="row" alignItems="end" justifyContent="start" mx={2}>
            <Box>
              <Typography variant="h5" component="h5">
                Filtrar
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="start" mx={2}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  name={'name'}
                  control={control}
                  render={({
                    field: { value, onChange, ...props },
                    fieldState: { error },
                  }) => (
                    <Input
                      value={value}
                      placeholder='Nome ou numero'
                      onChange={onChange}
                      fullWidth
                      sx={{ mb: 4 }}
                      {...props}
                    />
                  )}
                />
              </form>
            </Box>
          </Box>
        </div> */}
        {rows && rows.map((row: any, index: any) =>
          <Card sx={{ marginBottom: 0.5 }} key={index} >
            <CardContent>
              <Box display="flex" justifyContent="space-between">
                <Box>

                  <Typography display="flex" gutterBottom variant="h5" component="div">
                    {row.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {row.phone}
                  </Typography>
                </Box>
                <Box>
                  <Box>
                    <Tooltip title={'Agendamento'}>
                      <IconButton component={Link}
                        to={`/company/clients/${row.id}/schedules`} aria-label="schedules" >
                        <ReceiptLongIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={'Editar'}>
                      <IconButton aria-label="edit" onClick={() => handleToggleEditModal(row)}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={'Remover'}>
                      <IconButton aria-label="delete" onClick={() => handleClickOpenDelete(row)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>

                  </Box>
                </Box>

              </Box>
            </CardContent>
          </Card>
        )}

        {!rows.length && (
          <Typography display="flex" justifyContent='center' alignItems='center' gutterBottom variant="h5" component="div">
            Cliente não encontrado
          </Typography>
        )}

        {openModal && (
          <ModalAddClient
            open={openModal}
            handleClose={handleToggleModal}
            reload={load}
            selected={selectedClient}
          />
        )}

        <>
          <Dialog
            open={openDeleteModal}
            onClose={handleCloseDelete}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              {"Remover Cliente"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Tem certeza que deseja remover {clientDelete?.name}?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleCloseDelete}>
                Cancelar
              </Button>
              <Button onClick={handleDelete} autoFocus>
                Remover
              </Button>
            </DialogActions>
          </Dialog>
        </>
      </>
    </AsideBar>
  );
};

export default Clients;
