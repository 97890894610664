import styled from 'styled-components';

export const Container = styled.div`
  header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
`;
