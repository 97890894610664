import React from 'react'
import { Box, Button, Typography } from '@mui/material';
import logo from '../../../assets/images/logo.png';
import {
    Container,
    Content,
    AnimationContainer,
    Background,
    Img,
} from './styles';
import { useNavigate } from 'react-router-dom';
import { CustomButton } from '../../../components/atoms/Button';

const SucessSchedule = () => {
    const navigate = useNavigate();

    function handleNavigate(){
        navigate('/agendar')
    }

    return (
        <>
            <Container>
                <Content>
                    <AnimationContainer>
                        <Img src={logo} alt="Logo WaSender" />
                        <Typography component="div">
                            <Box mt={1} sx={{ minWidth: 120 }}>
                                <Typography variant="h5" gutterBottom>
                                    Agendamento efetuado com sucesso
                                </Typography>
                            </Box>
                        </Typography>
                    </AnimationContainer>
                </Content>
                <Background />
            </Container>
        </>
    )
}

export default SucessSchedule