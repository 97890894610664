

import api from '../api';

export async function PublicLoadCompanyDetail(code: string): Promise<any[]> {
    const headers = { 'code': code };
    const response = await api.get('/public/company', { headers });
    return response.data;
}

export async function PublicLoadServices(code: string): Promise<any[]> {
    const headers = { 'code': code };
    const response = await api.get('/public/services', { headers });
    return response.data;
}

export async function PublicLoadUsers(code: string): Promise<any[]> {
    const headers = { 'code': code };
    const response = await api.get('/public/users', { headers });
    return response.data;
}

export async function PublicLoadOpenSchedules(userId:string, code: string): Promise<any[]> {
    const headers = { 'code': code };
    const response = await api.get('/public/month/user', { params: {userId},  headers });
    return response.data;
}

/**Criar um agendamento
 */
export async function PublicCreateSchedule(
    schedule: ICreateScheduleModel, code: string
  ): Promise<IScheduleModel> {
    const headers = { 'code': code };
    const response = await api.post('/public/schedule', schedule, { headers });
    return response.data;
  }