import styled, { keyframes } from "styled-components";
// import signInBackgroundImage from '../../../assets/images/auth_background.jpg';

export const H2 = styled.h2`
h2 {
  font-family: 'DM Sans', 'Roboto', serif !important;
}
`;

export const Img = styled.img`
  width: 235px;
  height: 71px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  place-content: center;
  align-items: center;
  width: 100%;
  max-width: 35rem;
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;
export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  place-content: center;
  align-items: center;
  animation: ${appearFromLeft} 1s;

  form {
    margin: 80px 0;
    width: 340px;
    text-align: center;

    h1 {
      margin-bottom: 24px;
    }
  }
`;