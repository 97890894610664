import React, { createContext, useCallback, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { SignIn } from "../../services/userService";
import {
  getUserLocalStorage,
  setLocalStorage,
  removeUserLocalStorage,
} from "../../utils/userStorage";

interface AuthState {
  user: UserModel;
}

interface AuthContextData {
  user: UserModel;

  sigIn(credentials: UserForLogin): Promise<UserModel | undefined>;

  signOut(): void;
}

type Props = {
  children?: React.ReactNode;
};

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate();
  const [data, setData] = useState<AuthState>(() => {
    const user: UserModel = getUserLocalStorage();

    if (!!user && user.enabled) {
      return { user };
    }

    return {} as AuthState;
  });

  const sigIn = useCallback(async ({ phone, password }: UserForLogin) => {
    try {
      if(!phone.startsWith("55") && password.length >=8){
        phone = "55"+phone;
      }
      const user: UserModel = await SignIn({ phone, password });

      if (!!user && user.enabled) {
        setLocalStorage(user);
        setData({ user });
        return user;
      } else {
        toast.error("Usuário não encontrado, inativo ou sem acesso!");
        return user;
      }
    } catch (error) {
      removeUserLocalStorage();
      setData({} as AuthState);
      toast.error("Usuário ou senha incorretos, verifique e tente novamente.");
    }
  }, []);

  const signOut = useCallback(() => {
    removeUserLocalStorage();
    setData({} as AuthState);
    navigate("/auth");
  }, []);

  return (
    <AuthContext.Provider value={{ user: data.user, sigIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
}

export { AuthProvider, useAuth };
