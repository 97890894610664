import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { useAppLoading } from '../../../context/loading';
import { CustomButton } from '../../atoms/Button';
import { Input } from '../../atoms/Input';

import {
  CreateClient,
  CreateService,
  UpdateClient,
  UpdateServiceById,
} from '../../../services/companyService';
import CustomModal from '../CustomModal';
import { Container } from './styles';

interface IProps {
  open: boolean;
  handleClose: () => void;
  reload: () => void;
  selected?: IClientModel;
}

const schema = yup
  .object({
    name: yup.string().required(),
    phone: yup.string().required(),
  })
  .required();

export const ModalAddClient: React.FC<IProps> = ({
  open,
  handleClose,
  reload,
  selected,
}) => {
  const { control, handleSubmit, reset } = useForm<IClientModel>({
    resolver: yupResolver(schema),
  });

  const { setLoading } = useAppLoading();

  useEffect(() => {
    console.log(selected);
    if (selected) {
      reset(selected);
    }
  }, []);

  const onSubmit: SubmitHandler<IClientModel> = (data) => {
    setLoading(true);
    if (!!selected) {
      UpdateClient(data)
        .then(() => {
          toast.success('Serviço criado com sucesso.');
          reset();
          reload();
          handleClose();
        })
        .catch(() => {
          toast.error('Erro ao editar serviço, tente novamente mais tarde.');
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      CreateClient(data)
        .then(() => {
          toast.success('Serviço criada com sucesso.');
          reset();
          reload();
          handleClose();
        })
        .catch(() => {
          toast.error('Erro ao criar empresa, tente novamente mais tarde.');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (

    <CustomModal open={open} handleClose={handleClose}>
      <div
        style={{
          maxHeight: '78vh',
          width: '80vw',
        }}
      >

        <Typography id="modal-modal-title" variant="h6" component="h2" mb={4}>
          {!!selected ? 'Editar Client' : 'Novo Cliente'}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name={'name'}
            control={control}
            render={({
              field: { value, onChange, ...props },
              fieldState: { error },
            }) => (
              <Input
                label={'Nome'}
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error?.message}
                fullWidth
                sx={{ mb: 4 }}
                {...props}
              />
            )}
          />

          <Controller
            name="phone"
            control={control}
            render={({
              field: { value, onChange, ...props },
              fieldState: { error },
            }) => (
              <Input
                label={'Telefone'}
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error?.message}
                fullWidth
                sx={{ mb: 4 }}
                {...props}
              />
            )}
          />
          <Container>
            <CustomButton className='button' variant="contained" type={'submit'}>
              Salvar
            </CustomButton>
          </Container>
        </form>
      </div>
    </CustomModal>
  );
};
