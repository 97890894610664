import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useAppLoading } from '../../../context/loading';

interface ILoadingProps {
  children: React.ReactElement;
}

export const LoadingComponent: React.FC<ILoadingProps> = ({ children }) => {
  const { loading, setLoading } = useAppLoading();
  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 999 }}
        open={loading}
        onClick={() => setLoading(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {children}
    </>
  );
};
