import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { Box } from '@mui/system';
import { SubmitHandler, useForm } from 'react-hook-form';
import InfoIcon from '@mui/icons-material/Info';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { CustomButton } from '../../../components/atoms/Button';
import { AsideBar } from '../../../components/templates/AsideBar';
import { useAppLoading } from '../../../context/loading';
import { UpdateColaboratorSettingsById, loadColaboratorSettings } from '../../../services/colaboratorService';

const ListDay = [
  'domingo',
  'segunda',
  'terça',
  'quarta',
  'quinta',
  'sexta',
  'sabado',
];

const schema = yup
  .object({
    // workDays: {
    //   domingo : {
    //     startHour: yup.number().required(),
    //     endHour: yup.number().required(),
    //   }
    //},
    //numEmployee: yup.number().required(),
    serviceMinuteTimeout: yup.number().required(),
  })
  .required();

const ColaboratorSettings = () => {
  const { setLoading } = useAppLoading();
  const [rows, setRows] = useState<SettingsModal | undefined>();

  const { register, handleSubmit, reset } = useForm<ICreateSettingsModal>({
    resolver: yupResolver(schema),
  });

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const loadSettings = () => {
      setLoading(true);
      loadColaboratorSettings().then((res) => {
        setRows(res);
        setLoading(false);
        return res;
      })
      .finally(() => { setLoading(false);});
    
  };

  useEffect(() => {
    loadSettings();
  }, []);

  const onSubmit: SubmitHandler<ICreateSettingsModal> = (data) => {
    setLoading(true);
    if (data) {
      UpdateColaboratorSettingsById(data)
        .then((updated) => {
          setRows(updated);
          toast.success('Definições Atualizada com sucesso.');
          reset();
          loadSettings();
        })
        .catch(() => {
          toast.error('Erro ao editar definições, tente novamente mais tarde.');
          reset();
        })
        .finally(() => {
          setLoading(false);
          handleClose();
        });
    }
  };

  return (
    <AsideBar>
      <>
        <header>
          <Typography component="div" mb={1}>
            <Box sx={{ fontWeight: 'bold' }}>Configuraçoes da agenda</Box>
          </Typography>
        </header>
        <Grid container>
          <Grid item xs={12}>
            {rows && (
              <Card style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <CardContent>
                  <Grid container>
                    <Grid item xs={12}>
                      <Box>
                        <Box
                          marginBottom={3}
                          display="flex"
                          flexDirection="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Typography
                            gutterBottom
                            variant="h5"
                            component="h5"
                          >
                            Definições
                          </Typography>
                          <Tooltip title={'Editar'}>
                            <IconButton
                              aria-label="edit"
                              onClick={handleClickOpen}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Box>
                      <Divider variant="middle" />
                      <Typography component="div" marginTop={2}>
                        <Typography component="div" marginTop={3}>
                          <Box fontWeight="fontWeightBold" m={1}>
                            Dias da semana de trabalho:
                          </Box>
                          <SettingsHours rows={rows?.workDays} />
                        </Typography>

                        {/* <Box display="flex">
                          <Box fontWeight="fontWeightBold" m={1}>
                            Número de funcionários:
                          </Box>

                          <Box fontStyle="normal" m={1}>
                            {rows?.numEmployee}
                          </Box>
                        </Box> */}

                        <Box display="flex">
                          <Box fontWeight="fontWeightBold" m={1}>
                            Tempo médio de Serviço:
                          </Box>

                          <Box fontStyle="normal" m={1}>
                            {rows?.serviceMinuteTimeout}min
                          </Box>
                        </Box>
                        <Box display="flex">
                          <Box fontWeight="fontWeightBold" m={1}>
                            Exibir no robo de conversa:
                          </Box>

                          <Box fontStyle="normal" m={1}>
                            {rows?.botEnabled ? 'Sim' : 'Não'}
                          </Box>
                        </Box>
                      </Typography>

                        {/* BOX DE EDITAR CONFIGS */}
                      <Box>
                        <Dialog
                          fullWidth
                          open={open}
                          onClose={handleClose}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">
                            {'Editar definições'}
                          </DialogTitle>
                          {rows && (
                            <form onSubmit={handleSubmit(onSubmit)}>
                              <Box
                                padding={2}
                                display="flex"
                                flexDirection="column"
                              >
                                <TextField
                                  sx={{ display: 'none' }}
                                  required
                                  id="standard-required"
                                  label="Required"
                                  defaultValue={rows?._id}
                                  {...register('_id')}
                                />
                                <Box fontWeight="fontWeightBold">
                                  Dias de trabalho:
                                </Box>
                                {rows.workDays && (
                                  <>
                                    <SettingHour
                                      key={rows.workDays?.domingo._id}
                                      register={register}
                                      workDay={rows.workDays?.domingo}
                                      title="Domingo"
                                      registerContent="workDays.domingo"
                                    />
                                    <SettingHour
                                      key={rows.workDays?.segunda._id}
                                      register={register}
                                      workDay={rows.workDays?.segunda}
                                      title="Segunda"
                                      registerContent="workDays.segunda"
                                    />
                                    <SettingHour
                                      key={rows.workDays?.terca._id}
                                      register={register}
                                      workDay={rows.workDays?.terca}
                                      title="Terça"
                                      registerContent="workDays.terca"
                                    />
                                    <SettingHour
                                      key={rows.workDays?.quarta._id}
                                      register={register}
                                      workDay={rows.workDays?.quarta}
                                      title="Quarta"
                                      registerContent="workDays.quarta"
                                    />
                                    <SettingHour
                                      key={rows.workDays?.quinta._id}
                                      register={register}
                                      workDay={rows.workDays?.quinta}
                                      title="Quinta"
                                      registerContent="workDays.quinta"
                                    />
                                    <SettingHour
                                      key={rows.workDays?.sexta._id}
                                      register={register}
                                      workDay={rows.workDays?.sexta}
                                      title="Sexta"
                                      registerContent="workDays.sexta"
                                    />
                                    <SettingHour
                                      key={rows.workDays?.sabado._id}
                                      register={register}
                                      workDay={rows.workDays?.sabado}
                                      title="Sábado"
                                      registerContent="workDays.sabado"
                                    />
                                  </>
                                )}

                                {/* <TextField
                                  {...register('numEmployee')}
                                  id="standard-multiline-flexible"
                                  label="Número de funcionarios"
                                  defaultValue={rows?.numEmployee}
                                  multiline
                                  maxRows={4}
                                  sx={{ mb: 2 }}
                                /> */}

                                <TextField
                                  {...register('serviceMinuteTimeout')}
                                  id="standard-multiline-flexible"
                                  label="Tempo médio de Serviço em minutos"
                                  defaultValue={rows?.serviceMinuteTimeout}
                                  multiline
                                  maxRows={4}
                                  sx={{ mb: 2 }}
                                />

                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      defaultChecked={rows?.botEnabled}
                                      {...register('botEnabled', {
                                        required: true,
                                      })}
                                      color="primary"
                                    />
                                  }
                                  label="Exibir no robô de conversa"
                                />

                                <Box sx={{ mt: 4 }}>
                                  <CustomButton
                                    variant="contained"
                                    color={'inherit'}
                                    type={'button'}
                                    onClick={handleClose}
                                    sx={{ mr: 1 }}
                                  >
                                    Fechar
                                  </CustomButton>

                                  <CustomButton
                                    variant="contained"
                                    type={'submit'}
                                  >
                                    Salvar
                                  </CustomButton>
                                </Box>
                              </Box>
                            </form>
                          )}
                        </Dialog>
                      </Box>

                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            )}
          </Grid>
        </Grid>
      </>
    </AsideBar>
  );
};

interface IProps {
  rows?: SettingHourModel;
  workDay?: any;
  register?: any;
  title?: string;
  registerContent?: string;
}
const SettingHour: React.FC<IProps> = ({
  workDay,
  register,
  registerContent,
  title,
}) => {
  return (
    <>
      <Typography variant="h5">{title}</Typography>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        gap={1}
      >
        <FormControlLabel
          control={
            <Checkbox
              {...register(`${registerContent}.enabled`)}
              defaultChecked={workDay.enabled}
              color="primary"
            />
          }
          label="Ativo"
        />
        <TextField
          type="number"
          id="standard-multiline-flexible"
          label="Abertura"
          defaultValue={workDay.startHour}
          inputProps={{ inputMode: 'numeric' }}
          multiline
          maxRows={4}
          sx={{ mb: 2 }}
          {...register(`${registerContent}.startHour`, {
            min: 0,
            max: 23,
            required: true,
          })}
        />
        <TextField
          type="number"
          id="standard-multiline-flexible"
          label="Fechamento"
          defaultValue={workDay.endHour}
          inputProps={{ inputMode: 'numeric' }}
          multiline
          maxRows={4}
          sx={{ mb: 2 }}
          {...register(`${registerContent}.endHour`, {
            min: 0,
            max: 59,
            required: true,
          })}
        />
      </Box>
    </>
  );
};

const SettingsHours: React.FC<IProps> = ({ rows }) => {
  const getStatus = (status?: boolean) => {
    return (
      <Tooltip
        title={status ? 'Aberto' : 'Fechado'}
        enterTouchDelay={0}
        arrow={true}
        placement={'right'}
      >
        <IconButton type={'button'}>
          <InfoIcon sx={{ fontSize: '1.2rem' }} />
        </IconButton>
      </Tooltip>
    );
  };

  return (
    <Typography component="div" marginTop={2}>
      <Box display="flex">
        <Box fontWeight="fontWeightBold" m={1}>
          Domingo:
        </Box>
        <Box
          fontStyle="normal"
          m={1}
          style={{
            textDecorationLine: !rows?.domingo.enabled ? 'line-through' : '',
            textDecorationStyle: 'solid',
          }}
        >
          {rows?.domingo.startHour}h - {rows?.domingo.endHour}h
        </Box>
        {getStatus(rows?.domingo?.enabled)}
      </Box>

      <Box display="flex">
        <Box fontWeight="fontWeightBold" m={1}>
          Segunda:
        </Box>
        <Box
          fontStyle="normal"
          m={1}
          style={{
            textDecorationLine: !rows?.segunda.enabled ? 'line-through' : '',
            textDecorationStyle: 'solid',
          }}
        >
          {rows?.segunda.startHour}h - {rows?.segunda.endHour}h
        </Box>
        {getStatus(rows?.segunda?.enabled)}
      </Box>

      <Box display="flex">
        <Box fontWeight="fontWeightBold" m={1}>
          Terça:
        </Box>
        <Box
          fontStyle="normal"
          m={1}
          style={{
            textDecorationLine: !rows?.terca.enabled ? 'line-through' : '',
            textDecorationStyle: 'solid',
          }}
        >
          {rows?.terca.startHour}h - {rows?.terca.endHour}h
        </Box>
        {getStatus(rows?.terca?.enabled)}
      </Box>

      <Box display="flex">
        <Box fontWeight="fontWeightBold" m={1}>
          Quarta:
        </Box>
        <Box
          fontStyle="normal"
          m={1}
          style={{
            textDecorationLine: !rows?.quarta.enabled ? 'line-through' : '',
            textDecorationStyle: 'solid',
          }}
        >
          {rows?.quarta.startHour}h - {rows?.quarta.endHour}h
        </Box>
        {getStatus(rows?.quarta?.enabled)}
      </Box>

      <Box display="flex">
        <Box fontWeight="fontWeightBold" m={1}>
          Quinta:
        </Box>
        <Box
          fontStyle="normal"
          m={1}
          style={{
            textDecorationLine: !rows?.quinta.enabled ? 'line-through' : '',
            textDecorationStyle: 'solid',
          }}
        >
          {rows?.quinta.startHour}h - {rows?.quinta.endHour}h
        </Box>
        {getStatus(rows?.quinta?.enabled)}
      </Box>

      <Box display="flex">
        <Box fontWeight="fontWeightBold" m={1}>
          Sexta:
        </Box>
        <Box
          fontStyle="normal"
          m={1}
          style={{
            textDecorationLine: !rows?.sexta.enabled ? 'line-through' : '',
            textDecorationStyle: 'solid',
          }}
        >
          {rows?.sexta.startHour}h - {rows?.sexta.endHour}h
        </Box>
        {getStatus(rows?.sexta?.enabled)}
      </Box>

      <Box display="flex">
        <Box fontWeight="fontWeightBold" m={1}>
          Sábado:
        </Box>
        <Box
          fontStyle="normal"
          m={1}
          style={{
            textDecorationLine: !rows?.sabado.enabled ? 'line-through' : '',
            textDecorationStyle: 'solid',
          }}
        >
          {rows?.sabado.startHour}h - {rows?.sabado.endHour}h
        </Box>
        {getStatus(rows?.sabado?.enabled)}
      </Box>
    </Typography>
  );
};
export default ColaboratorSettings;
