import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { ThemeProvider } from '@mui/material';
import Box from '@mui/material/Box';
import { DarkTheme, LightTheme } from '../../themes';
import { getTheme, setTheme } from '../../utils/themeLocalStorage';

interface IThemeContextData {
  themeName: string;
  toggleTheme: () => void;
}

interface IPageProps {
  children: React.ReactElement;
}

const ThemeContext = createContext({} as IThemeContextData);

export const useAppThemeContext = () => {
  return useContext(ThemeContext);
};

export const AppThemeProvider: React.FC<IPageProps> = ({ children }) => {
  const [themeName, setThemeName] = useState<string>(() => getTheme());
  const toggleTheme = useCallback(() => {
    setThemeName((oldThemeName) => {
      if (oldThemeName === 'light') {
        setTheme('dark');
        return 'dark';
      } else {
        setTheme('light');
        return 'light';
      }
    });
  }, []);

  const theme = useMemo(() => {
    if (themeName === 'light') {
      setTheme('light');
      return LightTheme;
    }
    setTheme('dark');
    return DarkTheme;
  }, [themeName]);

  return (
    <ThemeContext.Provider value={{ themeName, toggleTheme }}>
      <ThemeProvider theme={theme}>
        <Box width="100vw" bgcolor={theme.palette.background.default}>
          {children}
        </Box>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};
