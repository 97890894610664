import React, { lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from '../context/auth/auth';
import { ProtectedRoute } from './ProtectedRoute';
import ColaboratorSettings from '../pages/colaborator/ColaboratorSettings';
import ColaboratorSchedule from '../pages/colaborator/ColaboratorSchedule';
import RegularSchedule from '../pages/public/regularSchedule';
import SucessSchedule from '../pages/public/sucessSchedule';
import Clients from '../pages/company/Client';
import ClientSchedules from '../pages/company/ClientSchedules';

const SigIn = lazy(() => import('../pages/common/SignIn'));
const NotAccessPage = lazy(() => import('../pages/common/403Page'));
const PageNotFound = lazy(() => import('../pages/common/404Page'));
const AdminCompanies = lazy(() => import('../pages/admin/Companies'));
const Services = lazy(() => import('../pages/company/Services'));
const Connect = lazy(() => import('../pages/company/Connect'));
const PerfilPassword = lazy(() => import('../pages/common/Perfil'));
const Settings = lazy(() => import('../pages/company/Settings'));
const ScheduleV2 = lazy(() => import('../pages/company/Schedule'));
const ListUsers = lazy(() => import('../pages/company/User/ListAll'));

export const AppRoutes = () => {
  const { user } = useAuth();
  const roles = {
    admin: ['ADMIN'],
    supervisor: ['SUPERVISOR'],
    colorador: ['COLABORADOR']
  };

  return (
    <Routes>
      <Route element={<ProtectedRoute isPrivate={false} />}>
        <Route path="/auth" element={<SigIn />} />
        <Route path="/agendar" element={<RegularSchedule />} />
        <Route path="/sucessSchdule" element={<SucessSchedule />} />
      </Route>

      {/*/!*Protected Admin Routes*!/*/}
      <Route
        path={'/admin'}
        element={<ProtectedRoute isPrivate={true} roles={roles.admin} />}
      >
        <Route path={'companies'} element={<AdminCompanies />} />
      </Route>
      {/*Protected Admin Routes*/}

      {/*Company Routes*/}
      <Route
        path={'/company'}
        element={<ProtectedRoute isPrivate={true} roles={roles.supervisor} />}
      >
        <Route path={'services'} element={<Services />} />
        <Route path={'connect'} element={<Connect />} />
        <Route path={'schedule'} element={<ScheduleV2 />} />
        <Route path={'settings'} element={<Settings />} />
        <Route path={'users'} element={<ListUsers />} />
        <Route path={'clients'} element={<Clients />} />
        <Route path={'clients/:clienteid/schedules'} element={<ClientSchedules />} />
      </Route>
      {/*Company Routes*/}

      {/*Company Routes*/}
      <Route
        path={'/colaborator'}
        element={<ProtectedRoute isPrivate={true} roles={roles.colorador} />}
      >
        <Route path={'schedule'} element={<ColaboratorSchedule />} />
        <Route path={'settings'} element={<ColaboratorSettings />} />
      </Route>
      {/*Company Routes*/}

      {/*Common Routes*/}
      <Route path={'/perfil'} element={<ProtectedRoute isPrivate={true} />}>
        <Route path={'password'} element={<PerfilPassword />} />
      </Route>

      <Route path="/not-access" element={<NotAccessPage />} />
      <Route path="/page-not-found" element={<PageNotFound />} />
      <Route path="*" element={<Navigate to="/page-not-found" replace />} />

      {user && roles.admin.includes(user?.profile) ? (
        <Route
          path="/"
          element={<Navigate to="/admin/companies" replace />}
        />
      ) : roles.supervisor.includes(user?.profile) ? (
        <Route path="/" element={<Navigate to="/company/schedule" replace />} />
      ) : <Route path="/" element={<Navigate to="/colaborator/schedule" replace />} />}
      {/*Common Routes*/}
    </Routes>
  );
};
