import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from '@mui/material';
import { Box } from '@mui/system';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import CustomModal from '../CustomModal';
import { CustomButton } from '../../atoms/Button';
import { CreateSchedule, loadClients, loadServices } from '../../../services/companyService';
import { useAppLoading } from '../../../context/loading';
import { Container } from './styles';

interface IProps {
  open: boolean;
  handleClose: () => void;
  scheduleId: any;
  date: any;
  hour: number;
  minutes: number;
  user: string;
}

export const ModalCreateSchedule: React.FC<IProps> = ({
  open,
  handleClose,
  scheduleId,
  date,
  hour,
  minutes,
  user
}) => {
  const { setLoading } = useAppLoading();
  const [services, setServices] = useState<IServiceModel[]>([]);
  const [serviceSelected, setServiceSelected] = useState<string | null>(null);
  const [rows, setRows] = useState<IClientModel[]>([]);
  const [clientPhone, setClientPhone] = useState('');

  const [selectedOption, setSelectedOption] = useState('');

  const { register, handleSubmit, setValue } = useForm<ICreateScheduleModel>({});

  const load = () => {
    setLoading(true);
    loadClients()
      .then((res) => {
        const translated = res.map((item: any) => {
          item.id = item._id;
          return item;
        });
        setRows(translated);
      })
      .finally(() => {
        setLoading(false);
      });

  };

  useEffect(() => {
    setLoading(true);
    load()
    loadServices()
      .then((res: any[]) => {
        setServices(res);
      })
      .catch((res) => {
        if (res?.response?.data?.msg) {
          toast.warn(res.response.data.msg);
        } else {
          toast.error('Erro ao carregar dados.');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    setServiceSelected(event.target.value as string);
    setSelectedOption(event.target.value as string);
  };

  const handleChangeClient = (event: any, value: any) => {
    if(value && value.phone){
      const telefone = value.phone;
      setClientPhone(telefone)
      setValue('clientPhone', telefone)
    }
  }

  function handleChangeNumero(event: any) {
    const addTelefone = event.target.value;
    setClientPhone(addTelefone);
  }

  const onSubmit: SubmitHandler<ICreateScheduleModel> = (data) => {
    setLoading(true);
    const newSchedule = { ...data, scheduleId, date, hour, minutes, user };
    CreateSchedule(newSchedule)
      .then((res: any) => {
        toast.success(res.msg);
        handleClose();
      })
      .catch((res) => {
        if (res?.response?.data?.msg) {
          toast.warn(res.response.data.msg);
        } else {
          toast.error('Erro ao carregar dados.');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <CustomModal open={open} handleClose={handleClose}>
      <div
        style={{
          maxHeight: '78vh',
          width: '80vw',
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box display="flex">
            <Box fontWeight="fontWeightBold" mb={0.5}>
              Data do agendamento:
            </Box>

            <Box fontStyle="normal" mx={0.5}>
              {date} {hour}:{minutes === 0 ? '00' : minutes}h
            </Box>
          </Box>

          <Stack my={2}>
            <Autocomplete
              id="client-autocomplete"
              freeSolo
              options={rows}
              getOptionLabel={(option: any) => option.name}
              renderInput={(params) =>
                <TextField {...params} id="cliente-name"
                  label="Nome do cliente"
                  {...register('clientName')}
                  fullWidth
                />
              }
              onChange={handleChangeClient}
            />
          </Stack>


          <Box display="flex" my={2}>
            <TextField
              fullWidth
              inputProps={{
                type: 'number',
                inputMode: 'numeric',
                pattern: '[0-9]*',
                readOnly: false,
              }}
              value={clientPhone}
              label="Telefone do cliente"
              {...register('clientPhone')}
              onChange={handleChangeNumero}
            />

          </Box>

          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Serviços</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Serviço"
              value={selectedOption}
              {...register('serviceId')}
              onChange={handleChange}
            >
              {services.map((service, index) => (
                <MenuItem key={index} value={service._id}>
                  {service.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Container>

            <CustomButton
              className='button'
              variant={'contained'}
              sx={{ mt: 2, mx: 2 }}
              disabled={!serviceSelected}
              type={'submit'}
            >
              Salvar
            </CustomButton>

            <CustomButton
              className='button'
              variant={'contained'}
              sx={{ mt: 2 }}
              onClick={handleClose}
            >
              Fechar
            </CustomButton>
          </Container>
        </form>
      </div>
    </CustomModal>
  );
};
