import React, { ReactNode, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import ScheduleIcon from '@mui/icons-material/Schedule';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import { useAppLoading } from '../../../context/loading';
import { CustomButton } from '../../atoms/Button';
import {
  CancelSchedule,
  DeleteSchedule,
  RenableSchedule,
  UpdateMonthDaySchedule,
} from '../../../services/companyService';
import { ModalCreateSchedule } from '../ModalCreateSchedule';
import { green, red } from '@mui/material/colors';
import { formatCurrency } from '../../../utils/formatCurrency';

interface IProps {
  data: ScheduleMouthHourModel;
  setReload: any;
  fullDate: string;
  selectedUser: string;
}

export const ScheduleHour: React.FC<IProps> = ({
  data,
  setReload,
  fullDate,
  selectedUser
}) => {
  const { _id, hour, minutes, schedules } = data;
  const geralStatus = data.status;
  const [avaliable, setAvaliable] = useState<boolean>(data.avaliable);
  const [openModalCreate, setOpenModalCreate] = useState<boolean>(false);
  const { setLoading } = useAppLoading();

  useEffect(() => { setAvaliable(data.avaliable) })

  const handleToggleModal = () => {
    setOpenModalCreate((prevState) => !prevState);
    setReload(true);
  };

  const handleStatusSchedule = (id: string, status: boolean) => {
    //["CONFIRMADO", "AGENDADO", "CANCELADO"]
    if (!status) {
      CancelSchedule(id)
        .then(() => {
          setReload(true);
        })
        .catch((res: any) => {
          if (res?.response?.data?.msg) {
            toast.warn(res.response.data.msg);
          } else {
            toast.error('Erro ao carregar dados.');
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      RenableSchedule(id)
        .then(() => {
          setReload(true);
        })
        .catch((res: any) => {
          if (res?.response?.data?.msg) {
            toast.warn(res.response.data.msg);
          } else {
            toast.error('Erro ao carregar dados.');
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleDeleteSchedule = (id: string) => {
    DeleteSchedule(id)
      .then((res: any) => {
        setReload(true);
        toast.warn(res.msg);
      })
      .catch((res: any) => {
        if (res?.response?.data?.msg) {
          toast.warn(res.response.data.msg);
        } else {
          toast.error('Erro ao carregar dados.');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleStatusDay = (status: boolean) => {
    UpdateMonthDaySchedule(_id, status)
      .then((res: any) => {
        toast.success(res.msg);
        setAvaliable(status);
        setReload(true);
      })
      .catch((res: any) => {
        if (res?.response?.data?.msg) {
          toast.warn(res.response.data.msg);
        } else {
          toast.error('Erro ao carregar dados.');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleOptions = (available: boolean): ReactNode => {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="end"
      >
        <IconButton
          color="primary"
          aria-label="criar agendamento"
          onClick={() => setOpenModalCreate(true)}
        >
          <AddCircleIcon fontSize={'large'} />
        </IconButton>
        {available ? (
          <IconButton
            color="primary"
            aria-label="fechar agendamento"
            onClick={() => handleStatusDay(false)}
          >
            <EventBusyIcon fontSize={'large'} />
          </IconButton>
        ) : (
          <IconButton
            color="primary"
            aria-label="abrir agendamento"
            onClick={() => handleStatusDay(true)}
          >
            <EventRepeatIcon fontSize={'large'} />
          </IconButton>
        )}
      </Box>
    );
  };

  return (
    <>
      <Card
        sx={{ m: 1 }}
        style={{
          backgroundColor:
            !avaliable && schedules.length === 0 ? 'silver' : '',
        }}
      >
        {schedules.length > 0 && (
          <Accordion sx={{ minHeight: 70, bgcolor: schedules.find(sh => sh.status === 'AGENDADO') ? green[300] : red[200] }}>
            <AccordionSummary id="panel1a-header" sx={{ p: 0 }}>
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: '#f1f1f1' }} aria-label="recipe">
                    {schedules.find(sh => ['AGENDADO', 'OVER_TIME'].includes(sh.status)) ? <MoreTimeIcon color={'primary'} /> : <EventBusyIcon color={'error'} />}
                  </Avatar>
                }
                // action={ schedules.find(sh => sh.status === 'AGENDADO' )  === undefined ? handleOptions(avaliable) : (<></>) }
                title={
                  schedules.length === 1
                    ? schedules[0].title
                    : schedules.map((sh) => sh.title).join(' - ')
                }
                subheader={`${hour}${minutes === 0 ? ':00' : `:${minutes}`}h`}
              />
              {
                ['AGENDADO', 'OVER_TIME'].includes(geralStatus) || schedules.find(sh => sh.status === 'AGENDADO') === undefined
                  ? handleOptions(avaliable) : (<></>)
              }
            </AccordionSummary>

            <AccordionDetails>
              {schedules.map((schedule, index) => (
                <CardContent key={index}>
                  <Typography component="div">
                    <Box display="flex" gap={1}>
                      <Typography fontWeight="fontWeightBold" mb={0.5}>
                        Cliente:
                      </Typography>

                      <Typography fontStyle="normal" mb={0.5}>
                        {schedule.cliente}
                      </Typography>
                    </Box>

                    <Box display="flex" gap={1}>
                      <Typography fontWeight="fontWeightBold" mb={0.5}>
                        Telefone:
                      </Typography>

                      <Typography fontStyle="normal" mb={0.5}>
                        {schedule.phone
                          ? schedule.phone.replace('@c.us', '')
                          : 'Sem telefone'}
                      </Typography>
                    </Box>
                    <Box display="flex" gap={1}>
                      <Typography fontWeight='fontWeightBold'>
                        Valor:
                      </Typography>
                      <Typography >
                        {formatCurrency(schedule.value)}
                      </Typography>
                    </Box>

                    <Box display="flex" gap={1}>
                      <Typography fontWeight="fontWeightBold" mb={0.5}>
                        Status:
                      </Typography>

                      <Typography fontStyle="normal" mb={0.5}>
                        {schedule.status}
                      </Typography>
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      mt={2}
                    >
                      {schedule.status !== 'CANCELADO' && (
                        <CustomButton
                          variant={'contained'}
                          sx={{ mx: 1 }}
                          onClick={() =>
                            handleStatusSchedule(schedule.id, false)
                          }
                        >
                          Cancelar <EventBusyIcon fontSize={'large'} />
                        </CustomButton>
                      )}
                      {schedule.status === 'CANCELADO' && (
                        <CustomButton
                          variant={'contained'}
                          onClick={() =>
                            handleStatusSchedule(schedule.id, true)
                          }
                        >
                          Voltar Agendamento{' '}
                          <EventRepeatIcon fontSize={'large'} />
                        </CustomButton>
                      )}
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="end"
                      >
                        <IconButton
                          color="primary"
                          aria-label="criar agendamento"
                          onClick={() => handleDeleteSchedule(schedule.id)}
                        >
                          <DeleteIcon fontSize={'large'} />
                        </IconButton>
                      </Box>
                    </Box>
                  </Typography>
                </CardContent>
              ))}
            </AccordionDetails>
          </Accordion>
        )}

        {schedules.length === 0 && (
          <CardHeader
            avatar={
              <Avatar sx={{ bgcolor: '#f1f1f1' }} aria-label="recipe">
                {['AGENDADO', 'OVER_TIME'].includes(geralStatus) ? <MoreTimeIcon color={'primary'} /> : <ScheduleIcon sx={{ color: 'gray' }} />}
              </Avatar>
            }
            action={handleOptions(avaliable)}
            title={'Horario Livre'}
            subheader={`${hour}${minutes === 0 ? ':00' : `:${minutes}`}h`}
          />
        )}
      </Card>

      {openModalCreate && (
        <ModalCreateSchedule
          open={openModalCreate}
          handleClose={handleToggleModal}
          scheduleId={_id}
          date={fullDate}
          hour={hour}
          minutes={minutes}
          user={selectedUser}
        />
      )}
    </>
  );
};
