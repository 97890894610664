import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Chip, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { AsideBar } from '../../../components/templates/AsideBar';
import { useAppLoading } from '../../../context/loading';
import { LoadClient, LoadClientSchedules } from '../../../services/companyService';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link, useParams } from 'react-router-dom';
import { formatCurrency } from '../../../utils/formatCurrency';

const ClientSchedules = () => {
  const { loading, setLoading } = useAppLoading();
  const [rows, setRows] = useState<IClientModel[]>([]);
  const [selectedClient, setSelectedClient] = useState<
    IClientModel | undefined
  >(undefined);

  const { clienteid } = useParams();
  const load = () => {
    setLoading(true);
    LoadClientSchedules(clienteid)
      .then((res) => {
        const translated = res.map((item: any) => {
          item.id = item._id;
          return item;
        });
        setRows(translated);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const loadClient = () => {
    setLoading(true);
    LoadClient(clienteid)
      .then((res) => {
        setSelectedClient(res);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    load();
    loadClient();
  }, []);

  return (
    <AsideBar>
      <>
        <header>
          <Box display="flex" flexDirection="row" justifyContent="start" mb={1}>
            <Box>
              <IconButton component={Link} to={`/company/clients`}>
                <ArrowBackIcon />
              </IconButton>
            </Box>
            <Box display="flex" alignItems="center">
              <Typography variant="h5" component="h5">
                {selectedClient?.name}
              </Typography>
              {/* <Typography variant="h5" component="h5">
                {selectedClient?.phone}
              </Typography> */}
            </Box>

          </Box>
        </header>
        {rows && rows.map((row: any, index: any) =>
          <Card sx={{ marginBottom: 0.5 }} key={index} >
            <CardContent>
              <Box display="flex" justifyContent="space-between">
                <Box>

                  <Typography display="flex" gutterBottom variant="h5" component="div">
                    <Box sx={{ display: "flex", alignItems: "center", gap: "2rem" }}>
                      <Typography variant="h5" color="inherit" noWrap>
                        {row.scheduleDate}  {row.scheduleHour}
                      </Typography>
                      <Chip label={row.status} color="success" />
                      {/* <Typography variant="body2" color="inherit" noWrap>
                        {row.status}
                      </Typography> */}
                    </Box>
                  </Typography>
                  <Typography display="flex" gutterBottom variant="h5" component="div">
                    <Box sx={{ width: '100%', display: "flex", alignItems: "center", gap: "2rem", justifyContent:"space-between" }}>
                      <Box>
                        <Typography variant="h5" color="inherit" noWrap>
                          {row.serviceName}
                        </Typography>
                      </Box>

                      <Box>
                        <Typography fontWeight='bold' variant="body2" color="inherit" noWrap>
                          {formatCurrency(row.value)}
                        </Typography>
                      </Box>
                    </Box>
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        )}

      </>
    </AsideBar>
  );
};

export default ClientSchedules;
