import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  place-content: center;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Img = styled.img`
  width: 235px;
  height: 71px;
`;

export const Loading = styled.div`
  font-weight: bold;
  display: inline-block;
  clip-path: inset(0 1.6ch 0 0);
  animation: l 1s steps(4) infinite;
  margin-top: 0.5rem;
  @keyframes l {
    to {
      clip-path: inset(0 -1ch 0 0);
    }
  }
`;
