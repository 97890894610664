import React, { useEffect, useState } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import CustomModal from '../CustomModal';
import { CustomButton } from '../../atoms/Button';
import { useAppLoading } from '../../../context/loading';
import { PublicCreateSchedule, PublicLoadServices } from '../../../services/publicService';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";

interface IProps {
  open: boolean;
  handleClose: () => void;
  scheduleId: any;
  date: any;
  hour: number;
  minutes: number;
  user: string;
  companyCode: string
}

export const ModalPublicCreateSchedule: React.FC<IProps> = ({
  open,
  handleClose,
  scheduleId,
  date,
  hour,
  minutes,
  user,
  companyCode
}) => {
  const { setLoading } = useAppLoading();
  const [services, setServices] = useState<IServiceModel[]>([]);
  const [captchaVerfied, setCaptchaVerfied] = useState(false);
  const [serviceSelected, setServiceSelected] = useState<string | null>(null);
  const navigate = useNavigate();

  const { register, handleSubmit } = useForm<ICreateScheduleModel>({});

  useEffect(() => {
    setLoading(true);
    PublicLoadServices(companyCode)
      .then((res: any[]) => {
        setServices(res);
      })
      .catch((res) => {
        if (res?.response?.data?.msg) {
          toast.warn(res.response.data.msg);
        } else {
          toast.error('Erro ao carregar dados.');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    setServiceSelected(event.target.value as string);
  };

  const onChangeRecaptcha = (value: any) => {
    console.log("Captcha value:", value);
    setCaptchaVerfied(true);
  }

  const onSubmit: SubmitHandler<ICreateScheduleModel> = (data) => {
    setLoading(true);
    const newSchedule = { ...data, scheduleId, date, hour, minutes, user };
    PublicCreateSchedule(newSchedule, companyCode)
      .then((res: any) => {
        toast.success(res.message);
        handleClose();
        navigate('/sucessSchdule')
      })
      .catch((res) => {
        if (res?.response?.data?.msg || res?.response?.data?.message) {
          toast.warn(res.response.data.msg ?? res?.response?.data?.message);
        } else {
          toast.error('Erro ao carregar dados.');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <CustomModal open={open} handleClose={handleClose}>
      <div
        style={{
          maxHeight: '78vh',
          width: '80vw',
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box display="flex">
            <Box mb={0.5}>
              <Typography gutterBottom variant="h5" component="h5">
                Data do agendamento:
              </Typography>
            </Box>

            <Box fontStyle="normal" mx={0.5}>
              <Typography gutterBottom variant="h5" component="h5">
                {date} {hour}:{minutes === 0 ? '00' : minutes}h
              </Typography>
            </Box>
          </Box>

          <Box display="flex" my={2}>
            <TextField
              // helperText="Please enter your name"
              id="cliente-name"
              label="Nome do cliente"
              {...register('clientName')}
              fullWidth
            />
          </Box>

          <Box display="flex" my={2}>
            <TextField
              fullWidth
              inputProps={{
                type: 'number',
                inputMode: 'numeric',
                pattern: '[0-9]*',
              }}
              label="Telefone do cliente"
              {...register('clientPhone')}
            />
          </Box>

          <Box display="flex" mb={2}>
            <FormControl fullWidth >
              <InputLabel id="demo-simple-select-label">Serviços</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Serviço"
                {...register('serviceId')}
                onChange={handleChange}
              >
                {services.map((service, index) => (
                  <MenuItem key={index} value={service._id}>
                    {service.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <ReCAPTCHA
            sitekey="6LeFBLYlAAAAACs4Z6FyeqhEdCv3fNuo0MCGLqB1"
            onChange={onChangeRecaptcha}
          />
          
          <CustomButton
            variant={'contained'}
            sx={{ mt: 2, mx: 2 }}
            disabled={!serviceSelected && !captchaVerfied}
            type={'submit'}
          >
            Salvar
          </CustomButton>

          <CustomButton
            variant={'contained'}
            sx={{ mt: 2 }}
            onClick={handleClose}
          >
            Fechar
          </CustomButton>

        </form>
      </div>
    </CustomModal>
  );
};
