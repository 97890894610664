import React from "react";
import { AuthProvider } from "./auth";
import "react-toastify/dist/ReactToastify.min.css";

type Props = {
  children?: React.ReactNode;
};

export const AppProvider: React.FC<Props> = ({ children }) => {
  return <AuthProvider>{children}</AuthProvider>;
};
