const setTheme = async (theme: string) => {
  return localStorage.setItem('theme', theme);
};

function getTheme(): string {
  const theme = localStorage.getItem('theme');

  if (!theme) {
    return 'light';
  }

  return theme;
}

export { setTheme, getTheme };
