import * as React from 'react';
import { Link } from 'react-router-dom';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { FaArrowLeft, FaArrowRight, FaSignOutAlt } from 'react-icons/fa';
import { GiHamburgerMenu } from 'react-icons/gi';
import Tooltip from '@mui/material/Tooltip';
import ToggleTheme from '../../molecules/ToggleTheme';
import { useAuth } from '../../../context/auth/auth';
import { AdminMenuItems, MenuColaboradorItems, MenuEmpresaItems } from './items';
import logo from '../../../assets/images/logo.png';
import { MenuItemComponent } from '../../molecules/MenuItem';
import { UserMenu } from '../../molecules/UserMenu';
import { Card, CardContent } from '@mui/material';

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

interface IPageProps {
  children: React.ReactElement;
}

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: '0px',//`calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: '0px'//`calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const Logo = () => {
  return (
    <Link
      to={'/'}
      style={{
        display: 'flex',
        justifyContent: 'end',
      }}
    >
      <img src={logo} alt={'Logo Wa sender'} width={'150px'} />
    </Link>
  );
};

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export const AsideBar: React.FC<IPageProps> = ({ children }) => {
  const { user, signOut } = useAuth();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Stack direction={'row'} sx={{ alignItems: 'center' }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: 'none' }),
              }}
            >
              <GiHamburgerMenu />
            </IconButton>
          </Stack>

          <div style={{ display: 'flex' }}>
            <ToggleTheme />
            <UserMenu />
          </div>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader
          sx={{
            d: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Logo />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <FaArrowRight /> : <FaArrowLeft />}
          </IconButton>
        </DrawerHeader>

        <Divider />
        {user?.profile === 'SUPERVISOR' && (
          <List>
            {MenuEmpresaItems.map((item, index) => (
              <MenuItemComponent key={index} item={item} open={open} />
            ))}
          </List>
        )}

        {user?.profile === 'ADMIN' && (
          <>
            <Divider />
            <List>
              {AdminMenuItems.map((item, index) => (
                <MenuItemComponent key={index} item={item} open={open} />
              ))}
            </List>
          </>
        )}

        {user?.profile === 'COLABORADOR' && (
          <List>
            {MenuColaboradorItems.map((item, index) => (
              <MenuItemComponent key={index} item={item} open={open} />
            ))}
          </List>
        )}
        <Divider />
        <List>
          <Tooltip title={open ? '' : 'Sair'} arrow placement={'right'}>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                onClick={signOut}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <FaSignOutAlt />
                </ListItemIcon>
                <ListItemText primary={'Sair'} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          </Tooltip>
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {/* <Card> */}
          <>{children}</>
        {/* </Card> */}
      </Box>
    </Box>
  );
};
