import api from '../api';

export async function SignIn(data: UserForLogin): Promise<UserModel> {
  const response = await api.post('/auth', data);

  return response.data;
}

export async function UpdatePassword(
  data: UpdatePasswordModel
): Promise<ResponseModel> {
  const response = await api.put('/perfil/password', data);

  return response.data;
}

export async function GetListUsers() {
  const response = await api.get('/company/user');
  return response.data;
}

export async function SaveUser(data: CreateUserModel) {
  const response = await api.post('/company/user', data);
  return response.data;
}

export async function EditUser(data: CreateUserModel) {
  const response = await api.put(`/company/user/${data.id}`, data);
  return response.data;
}
