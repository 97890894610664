import axios from "axios";
import {
  getUserLocalStorage,
  // removeUserLocalStorage,
} from "../utils/userStorage";

const api = axios.create({
  baseURL: process.env.REACT_APP_URL_BACKEND,
});

api.interceptors.request.use(
  async (config) => {
    const user = getUserLocalStorage();
    if (user) {
      config.headers = {
        Authorization: `Bearer ${user.token}`,
      };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (originalConfig.url !== "/auth" && err.response) {
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        // removeUserLocalStorage();
        // window.location.reload();
      }
    }
    return Promise.reject(err);
  }
);

export default api;
