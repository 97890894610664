import ApartmentIcon from '@mui/icons-material/Apartment';
import TapAndPlayIcon from '@mui/icons-material/TapAndPlay';
import ListAltIcon from '@mui/icons-material/ListAlt';
import SettingsIcon from '@mui/icons-material/Settings';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';

export const MenuEmpresaItems = [
  {
    title: 'Agendamentos',
    path: '/company/schedule',
    icon: <CalendarMonthIcon />,
  },
  {
    title: 'Serviços',
    path: '/company/services',
    icon: <ListAltIcon />,
  },
  {
    title: 'Clientes',
    path: '/company/clients',
    icon: <PersonSearchIcon />,
  },
  {
    title: 'Conexão',
    path: '/company/connect',
    icon: <TapAndPlayIcon />,
  },
  {
    title: 'Usuários',
    path: '/company/users',
    icon: <PeopleAltIcon />,
  },
  {
    title: 'Definições',
    path: '/company/settings',
    icon: <SettingsIcon />,
  },
];

export const MenuColaboradorItems = [
  {
    title: 'Agendamentos',
    path: '/colaborator/schedule',
    icon: <CalendarMonthIcon />,
  },
  {
    title: 'Definições',
    path: '/colaborator/settings',
    icon: <SettingsIcon />,
  },
];

export const AdminMenuItems = [
  { title: 'Empresas', path: '/admin/list-companies', icon: <ApartmentIcon /> },
];
