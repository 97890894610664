import styled from 'styled-components';

export const Container = styled.div`
 
      @media only screen and (max-width: 450px) {
      .button {
      width: 100%;
      }
    }
`;
