import React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';

export const Input = React.forwardRef(
  ({ value, variant, ...props }: TextFieldProps, _ref) => {
    return (
      <TextField
        value={value || ''}
        variant={variant ? variant : 'outlined'}
        {...props}
      />
    );
  },
);
