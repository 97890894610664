import { da } from 'date-fns/locale';
import api from '../api';

interface IScheduleProps {
  day: number;
  month: number;
  year: number;
}

export async function loadServices(): Promise<IServiceModel[]> {
  const response = await api.get('/company/service');
  return response.data;
}

export async function CreateService(
  data: ICreateServiceModel
): Promise<unknown> {
  const response = await api.post('/company/service', data);

  return response.data;
}

export async function DeleteService(
  serviceId: string,
): Promise<unknown> {
  const response = await api.delete(`/company/service/${serviceId}`);

  return response.data;
}

export async function loadSettings(): Promise<SettingsModal> {
  const response = await api.get(`/company/settings`);
  return response.data;
}

export async function loadUserSettings(userId: string): Promise<SettingsModal> {
  const response = await api.get(`/company/usersettings/${userId}`);
  return response.data;
}

export async function UpdateSettingsById(
  data: ICreateSettingsModal
): Promise<SettingsModal> {
  const response = await api.put(`/company/settings/${data._id}`, data);

  return response.data;
}

export async function UpdateUserSettingsById(
  data: ICreateSettingsModal
): Promise<SettingsModal> {
  const response = await api.put(`/company/usersettings/${data._id}`, data);

  return response.data;
}

export async function UpdateServiceById(
  data: ICreateServiceModel
): Promise<unknown> {
  const response = await api.put(`/company/service/${data._id}`, data);

  return response.data;
}
/* ROTAS DO WHATSAAPP CONNECT */

export async function CreateConnection(): Promise<{ message: string }> {
  const response = await api.put(`/company/wa/start`);

  return response.data;
}

export async function StatusConnection(): Promise<{
  connected: any;
  qrCode: any;
}> {
  const response = await api.get(`/company/wa/status`);

  return response.data;
}

/** Consultar os agendamentos da data recebida
 * @param day - optional
 */
export async function GetCompanySchedule({
  day,
  month,
  year,
}: IScheduleProps): Promise<unknown> {
  const params = new URLSearchParams();
  day && params.append('day', day.toString());
  month && params.append('month', month.toString());
  year && params.append('year', year.toString());

  const response = await api.get(`/company/schedule`, { params });

  return response.data;
}

/**Consultar agenda do mes */
export async function GetCompanyMonthSchedule(
  { month, year }: IScheduleProps,
  userId: string
): Promise<unknown> {
  const params = new URLSearchParams();
  //day && params.append('day', day);
  month && params.append('month', month.toString());
  year && params.append('year', year.toString());

  const response = await api.get(`/company/month/${userId}`, { params });

  return response.data;
}

/**Consultar agendametos dos dias da agenda
 * @param daysId Lista de ID dos ScheduleMouth.Days.Schedule._id
 */
export async function GetScheduleByDays(
  daysId: string[],
  userId: string
): Promise<unknown[]> {
  const response = await api.post('/company/schedule/filtrar', {
    days: daysId,
    userId,
  });
  return response.data;
}

/**Criar a agenda do mes */
export async function StartMonthSchedule(
  date: IScheduleProps,
  userId: string
): Promise<unknown[]> {
  const response = await api.post('/company/month/start', { date, userId });
  return response.data;
}

/**Criar um agendamento
 */
export async function CreateSchedule(
  schedule: ICreateScheduleModel
): Promise<IScheduleModel> {
  const response = await api.post('/company/schedule', schedule);
  return response.data;
}

/**Cancelar um agendamento
 * @param id Schedule._id
 */
export async function DeleteSchedule(id: string): Promise<IScheduleModel> {
  const response = await api.delete(`/company/schedule/${id}`);
  return response.data;
}

/**Cancelar um agendamento
 * @param id Schedule._id
 */
export async function CancelSchedule(id: string): Promise<IScheduleModel> {
  const response = await api.patch(`/company/schedule/${id}`, {
    status: 'CANCELADO',
  });
  return response.data;
}

/**Cancelar um agendamento
 * @param id Schedule._id
 */
export async function RenableSchedule(id: string): Promise<IScheduleModel> {
  const response = await api.patch(`/company/schedule/${id}`, {
    status: 'AGENDADO',
  });
  return response.data;
}

/**Atualizar status do dia da agenda
 */
export async function UpdateMonthDaySchedule(
  id: string,
  avaliable: boolean
): Promise<IScheduleModel> {
  const response = await api.patch(`/company/month/day/${id}`, { avaliable });
  return response.data;
}

/**Listar usuarios
 */
export async function GetUsers(): Promise<UserModel[]> {
  const response = await api.get(`/company/user`);
  return response.data;
}

/**Inserir um horairo ao dia
 */
export async function CreateCompanyMonthSchedule(
  dayId: string,
): Promise<IScheduleModel> {
  const response = await api.put(`/company/month/day/${dayId}`);
  return response.data;
}

export async function loadClients(name?: string): Promise<IClientModel[]> {
  const params = new URLSearchParams();
  name && params.append('name', name.toString());

  const response = await api.get('/company/clients', { params });
  return response.data;
}

export async function LoadClientSchedules(clientId: any): Promise<IClientModel[]> {
  const response = await api.get(`/company/clients/${clientId}/schedules`);
  return response.data;
}

export async function LoadClient(clientId: any): Promise<IClientModel> {
  
  const response = await api.get(`/company/clients/${clientId}`);
  return response.data;
}

export async function CreateClient(
  data: IClientModel
): Promise<unknown> {
  const response = await api.post('/company/clients', data);

  return response.data;
}

export async function UpdateClient(
  data: IClientModel
): Promise<unknown> {
  const response = await api.put(`/company/clients/${data._id}`, data);
  return response.data;
}

export async function DeleteClient(
  _id: any
): Promise<unknown> {
  const response = await api.delete(`/company/clients/${_id}`);
  return response.data;
}