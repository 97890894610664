import api from '../api';

interface IScheduleProps {
  day: number;
  month: number;
  year: number;
}

export async function loadColaboratorSettings(): Promise<SettingsModal> {
  const response = await api.get(`/colaborator/settings`);
  return response.data;
}

export async function UpdateColaboratorSettingsById(
  data: ICreateSettingsModal
): Promise<SettingsModal> {
  const response = await api.put(`/colaborator/settings/${data._id}`, data);

  return response.data;
}

/**Consultar agenda do mes */
export async function GetCompanyMonthSchedule(
  { month, year }: IScheduleProps,
  userId: string
): Promise<unknown> {
  const params = new URLSearchParams();
  //day && params.append('day', day);
  month && params.append('month', month.toString());
  year && params.append('year', year.toString());

  const response = await api.get(`/colaborator/month/${userId}`, { params });

  return response.data;
}

/**Consultar agendametos dos dias da agenda
 * @param daysId Lista de ID dos ScheduleMouth.Days.Schedule._id
 */
export async function GetScheduleByDays(
  daysId: string[],
  userId: string
): Promise<unknown[]> {
  const response = await api.post('/colaborator/schedule/filtrar', {
    days: daysId,
    userId,
  });
  return response.data;
}

/**Criar a agenda do mes */
export async function StartMonthSchedule(
  date: IScheduleProps,
  userId: string
): Promise<unknown[]> {
  const response = await api.post('/colaborator/month/start', { date, userId });
  return response.data;
}

/**Criar um agendamento
 */
export async function CreateSchedule(
  schedule: ICreateScheduleModel
): Promise<IScheduleModel> {
  const response = await api.post('/colaborator/schedule', schedule);
  return response.data;
}

/**Cancelar um agendamento
 * @param id Schedule._id
 */
export async function DeleteSchedule(id: string): Promise<IScheduleModel> {
  const response = await api.delete(`/colaborator/schedule/${id}`);
  return response.data;
}

/**Cancelar um agendamento
 * @param id Schedule._id
 */
export async function CancelSchedule(id: string): Promise<IScheduleModel> {
  const response = await api.patch(`/colaborator/schedule/${id}`, {
    status: 'CANCELADO',
  });
  return response.data;
}

/**Cancelar um agendamento
 * @param id Schedule._id
 */
export async function RenableSchedule(id: string): Promise<IScheduleModel> {
  const response = await api.patch(`/colaborator/schedule/${id}`, {
    status: 'AGENDADO',
  });
  return response.data;
}

/**Atualizar status do dia da agenda
 */
export async function UpdateMonthDaySchedule(
  id: string,
  avaliable: boolean
): Promise<IScheduleModel> {
  const response = await api.patch(`/colaborator/month/day/${id}`, { avaliable });
  return response.data;
}

/**Listar usuarios
 */
export async function GetUsers(): Promise<UserModel[]> {
  const response = await api.get(`/colaborator/user`);
  return response.data;
}

export async function EditUser(data: CreateUserModel) {
  const response = await api.put(`/colaborator/user`, data);
  return response.data;
}

/**Inserir um horairo ao dia
 */
export async function CreateMonthSchedule(
  dayId: string,
): Promise<IScheduleModel> {
  const response = await api.put(`/colaborator/month/day/${dayId}`);
  return response.data;
}
