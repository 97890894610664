import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify';
import { PublicLoadCompanyDetail, PublicLoadOpenSchedules, PublicLoadUsers } from '../../../services/publicService';
import { useAppLoading } from '../../../context/loading';
import { Avatar, Box, Card, CardHeader, Container, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Typography } from '@mui/material';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import { ModalPublicCreateSchedule } from '../../../components/organisms/ModalPublicCreateSchedule';
import { AnimationContainer, Content, Img } from './styles';
import logo from '../../../assets/images/logo.png';
import { green } from '@mui/material/colors';

const RegularSchedule = () => {
    const { setLoading } = useAppLoading();
    const [companyCode, setCompanyCode] = useState<string>();
    const [users, setUsers] = useState<UserModel[]>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [userSelected, setUserSelected] = useState<string | undefined>(undefined);
    const [scheduleList, setScheduleList] = useState<any[]>([]);
    const [openModalCreate, setOpenModalCreate] = useState<boolean>(false);
    const [scheduleSelected, setScheduleSelected] = useState<any>();
    const [company, setCompany] = useState<any>();
    const defaultBackground = {background : green[700]};

    useEffect(() => {
        const code = searchParams.get("z")?.toString();
        if (!code) {
            toast.warn("Nenhuma empresa informada");
            return;
        }
        setCompanyCode(code);

        setLoading(true);
        PublicLoadCompanyDetail(code!).then((res) => {
            setCompany(res);
        })
        .catch((err) => {
            //toast.warn(err.message);
            setCompany(null);
        });

        PublicLoadUsers(code!)
        .then((res) => {
            setUsers(res)
        })
        .catch((err) => {
            toast.warn(err.message);
            setUsers([])
        })
        .finally(() => {
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        if (userSelected) {
            handleLoadOpenSchedules(userSelected);
        }
    }, [userSelected]);

    const handleToggleModal = () => {
        setOpenModalCreate((prevState) => !prevState);
    };
    const handleSelectSchedule = (schedule:any) => {
        setScheduleSelected(schedule);
        setOpenModalCreate(true)
    };

    const handleLoadOpenSchedules = (user: any) => {
        setLoading(true);
        PublicLoadOpenSchedules(user, companyCode!)
            .then((list) => {
                setScheduleList(list);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleChange = (event: SelectChangeEvent<unknown>) => {
        setUserSelected(event.target.value as string);
    };

    return (
        <>
            <Box style={ company ? { background : `linear-gradient(to bottom right, ${company?.startColor}, ${company?.endColor})`} : defaultBackground}>
                { !company && 
                    <Content>
                    <AnimationContainer>
                        <Img src={logo} alt="Logo WaSender" />
                        <Typography component="div">
                            <Box mt={1} sx={{ minWidth: 120 }}>
                                <Typography variant="h5" gutterBottom>
                                    Agendamentos on-line
                                </Typography>
                            </Box>
                        </Typography>
                    </AnimationContainer>
                </Content>
                }
                { company &&
                <>
                    <header>
                        <Typography component="div" my={3} textAlign="center" style={{marginTop: '0px', paddingTop: '10px', color: 'white', fontWeight: '10%'}}>
                            <img 
                            src={`https://wa-sender.s3.amazonaws.com/${companyCode}/logo.jpeg`} 
                            alt="logo empresa" loading="lazy" 
                            style={{borderRadius: '50%', maxWidth: '200px'}}
                            />
                            <h2>{company?.name}</h2>
                            <Box sx={{ fontWeight: 'lighter' }}>Telefone: {company?.phone}</Box>
                            <Box sx={{ fontWeight: 'lighter' }}>{company?.address}</Box>
                        </Typography>
                    </header>
                    <Paper elevation={3}>

                        <Typography
                        component="div" 
                        textAlign="center" 
                        style={{backgroundColor : 'white', borderRadius: '5%', width: '100%', minHeight: '100vh'}}>
                            <Box sx={{ fontWeight: 'bold', paddingTop: '20px' }} my={3}>Agendamentos</Box>
                            <Box m={2} sx={{ width: 250 }} display="contents" >
                                <FormControl sx={{minWidth : '70vw'}}>
                                    <InputLabel id="users">Parceiros</InputLabel>
                                    <Select
                                    
                                        labelId="users"
                                        id="usersSelect"
                                        label="Parceiros"
                                        onChange={handleChange}
                                        value={userSelected || ''}
                                    >
                                        {users?.map((user, index) => (
                                            <MenuItem key={index} value={user.id}>
                                                {user.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>

                            <Box m={2} sx={{ minWidth: 120 }}>
                                {userSelected && scheduleList && scheduleList.length === 0 && (
                                    <Card sx={{ x: 1, m: 1 }} key={'no-schedule'}>
                                        <CardHeader
                                            avatar={
                                            <Avatar sx={{ bgcolor: '#f1f1f1' }} aria-label="recipe">
                                                <EventBusyIcon color={'primary'} />
                                            </Avatar>
                                            }
                                            title={'Sem agenda disponivel'}
                                            subheader={`Se preferir entre em contato pelo numero ${company?.phone}`}
                                        />
                                    </Card>
                                )}
                                {userSelected && scheduleList && scheduleList.map((day, indexSch) =>
                                    (<Box my={2} sx={{ minWidth: 120 }} key={indexSch}>
                                        <span>{day.day} {day.weekday}</span>
                                        {day.schedules.map((scheduleHour:any, index: any) => 
                                            (
                                                <Card sx={{ x: 1, m: 1 }} key={index}>
                                                    <CardHeader
                                                    avatar={
                                                    <Avatar sx={{ bgcolor: '#f1f1f1' }} aria-label="recipe">
                                                        <MoreTimeIcon color={'primary'} />
                                                    </Avatar>
                                                    }
                                                    action={<IconButton
                                                        color="primary"
                                                        aria-label="criar agendamento"
                                                        onClick={() => handleSelectSchedule({...scheduleHour, date : `${day.day}/${day.month > 9 ? day.month: `0${day.month}`}/${day.year}` })}>
                                                        <AddCircleIcon fontSize={'large'} />
                                                    </IconButton>}
                                                    title={'Horario Livre'}
                                                    subheader={`${scheduleHour.hour}${scheduleHour.minutes === 0 ? ':00' : `:${scheduleHour.minutes}`}h`}
                                                    />
                                                </Card>
                                                
                                                )
                                            )
                                        } 
                                    </Box>))
                                }
                            </Box>
                        </Typography>
                    </Paper>
                
                </>} 
            </Box>
            {openModalCreate && (
                <ModalPublicCreateSchedule
                open={openModalCreate}
                handleClose={handleToggleModal}
                scheduleId={scheduleSelected._id}
                date={scheduleSelected.date}
                hour={scheduleSelected.hour}
                minutes={scheduleSelected.minutes}
                user={userSelected!}
                companyCode={companyCode!}
                />
            )}
        </>
    )
}

export default RegularSchedule