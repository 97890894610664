import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { modalStyle } from '../../../assets/styles/global';

interface IProps {
  open: boolean;
  handleClose: () => void;
  children: React.ReactNode;
}

const CustomModal: React.FC<IProps> = ({ open, handleClose, children }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>{children}</Box>
    </Modal>
  );
};

export default CustomModal;
