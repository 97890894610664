import React, { createContext, useContext, useState } from 'react';
import { LoadingComponent } from '../../components/organisms/Loading';

interface ILoadingContextData {
  loading: boolean;
  setLoading: (value: boolean) => void;
}

interface IPageProps {
  children: React.ReactElement;
}

const LoadingContext = createContext({} as ILoadingContextData);

export const useAppLoading = () => {
  return useContext(LoadingContext);
};

export const AppLoadingProvider: React.FC<IPageProps> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <LoadingContext.Provider value={{ loading, setLoading }}>
      <LoadingComponent>{children}</LoadingComponent>
    </LoadingContext.Provider>
  );
};
