import React from 'react';
import { Container, Content, Img, Loading } from './styles';
import Typography from '@mui/material/Typography';
import logo from '../../../assets/images/logo.png';

const SuspenseLoading = () => {
  return (
    <Container>
      <Content>
        <Img src={logo} alt="Logo Wa Sender" />
        <Typography variant={'h6'}>
          <Loading>Carregando...</Loading>
        </Typography>
      </Content>
    </Container>
  );
};

export default SuspenseLoading;
