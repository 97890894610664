import React, { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import GlobalStyles from "./assets/styles/global";
import { AppProvider } from "./context/auth";
import { AppThemeProvider } from "./context/theme";
import { AppLoadingProvider } from "./context/loading";
import { AppRoutes } from "./routes";
import SuspenseLoading from "./pages/common/SuspenseLoading";

function App() {
  return (
    <Router>
      <GlobalStyles />
      <ToastContainer theme={"colored"} />
      <AppProvider>
        <AppThemeProvider>
          <AppLoadingProvider>
            <Suspense fallback={<SuspenseLoading />}>
              <AppRoutes />
            </Suspense>
          </AppLoadingProvider>
        </AppThemeProvider>
      </AppProvider>
    </Router>
  );
}

export default App;
