import React, { useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import { toast } from 'react-toastify';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AsideBar } from '../../../components/templates/AsideBar';
import { useAppLoading } from '../../../context/loading';
import { CustomButton } from '../../../components/atoms/Button';
import { ScheduleHour } from '../../../components/organisms/ScheduleHour';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  CreateMonthSchedule,
  GetCompanyMonthSchedule,
  GetScheduleByDays,
  GetUsers,
  StartMonthSchedule,
} from '../../../services/colaboratorService';
import { Container } from './style';

const ColaboratorSchedule = () => {
  const { setLoading } = useAppLoading();
  const [day, setDay] = useState<ScheduleMouthDayModel | null>(null);
  const [daySchedule, setDaySchedule] = useState<ScheduleMouthDayModel | null>(
    null
  );
  const [scheduleMouth, setScheduleMouth] = useState<ScheduleMouthModel | null>(
    null
  );
  const [value, setValue] = React.useState<DateTime | null>(DateTime.now());
  const [reload, setReload] = React.useState<boolean>(false);
  const [users, setUsers] = useState<UserModel[] | []>();
  const [userSelected, setUserSelected] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    GetUsers().then((res) => {
      setUsers(res);
      setLoading(false);
      return res;
    });
  }, []);

  useEffect(() => {
    console.log('useEffect day',day)
    if (day && day.schedules.length > 0) {
      const daysId = day?.schedules.map((el: ScheduleMouthHourModel) => el._id);
      setLoading(true);
      GetScheduleByDays(daysId!, userSelected!)
        .then((res: any[]) => {
          day!.schedules.forEach((element) => {
            element.schedules = [];
          });
          res.forEach((schedule) => {
            const index = day?.schedules.findIndex(
              (el: ScheduleMouthHourModel) =>
                el._id === schedule.scheduleMouthId
            );

            if (index !== -1) {
              day!.schedules[index].schedules.push(schedule);
            }
          });
          setDaySchedule(day);
        })
        .catch((res) => {
          if (res?.response?.data?.msg) {
            toast.warn(res.response.data.msg);
          } else {
            toast.error('Erro ao carregar dados.');
          }
        })
        .finally(() => {
          setLoading(false);
          setReload(false);
        });
    }
  }, [day]);

  useEffect(() => {
    if (userSelected) {
      handleChangeDate(value ?? DateTime.now());
    }
  }, [userSelected, reload]);

  const handleChangeDate = (newValue: DateTime | null) => {
    if (!userSelected) {
      toast.warn('Selecione um usuario');
      return;
    }
    setLoading(true);
    setValue(newValue);

    const date = {
      day: newValue!.day,
      month: newValue!.month,
      year: newValue!.year,
    };

    // if (
    //   scheduleMouth != null &&
    //   scheduleMouth?.mouth === Number(date.month) &&
    //   scheduleMouth?.year === Number(date.year) &&
    //   scheduleMouth.user === userSelected
    // ) {
    //   const index = scheduleMouth.days.findIndex(
    //     (el: any) => el.day === Number(date.day)
    //   );
    //   setDay(scheduleMouth.days[index]);
    //   setLoading(false);
    // } else {
      GetCompanyMonthSchedule(date, userSelected)
        .then((res: any) => {
          setScheduleMouth(res);
          const index = res.days.find((el: any) => el.day === Number(date.day));
          setDay(index);
        })
        .catch((res) => {
          if (res?.response?.data?.msg) {
            toast.warn(res.response.data.msg);
            setScheduleMouth(null);
            setDay(null);
            setDaySchedule(null);
          } else {
            toast.error('Erro ao carregar dados.');
          }
        })
        .finally(() => {
          setLoading(false);
        });
    //}
  };

  const handleOpenMouth = () => {
    const date = {
      day: value!.day,
      month: value!.month,
      year: value!.year,
    };

    StartMonthSchedule(date, userSelected!)
      .then((res: any) => {
        setScheduleMouth(res);
        const index = res.days.findIndex(
          (el: any) => el.day === Number(date.day)
        );
        setDay(res.days[index]);
      })
      .catch((res: any) => {
        if (res?.response?.data?.msg) {
          toast.warn(res.response.data.msg);
        } else {
          toast.error('Erro ao carregar dados.');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    setUserSelected(event.target.value as string);
  };

  const refresh = () => { setReload(true)}

  const inserirEcaixe = (dayId:string) => {
    setLoading(true);
    CreateMonthSchedule(dayId)
        .then((res: any) => {
          toast.warn(res.msg);
          setReload(true);
        })
        .catch((res:any) => {
          if (res?.response?.data?.msg) {
            toast.warn(res.response.data.msg);
          } else {
            toast.error('Erro ao processar requisição.');
          }
        })
        .finally(() => {
          setLoading(false);
        });
  }
  return (
    <AsideBar>
      <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={'br'}>
        <Container>
          <header>
            <Typography component="div">
              <Box sx={{ fontWeight: 'bold' }}>Agenda</Box>
            </Typography>
          </header>
          <Typography component="div">
            <Box mb={2}>
              <FormControl fullWidth>
                <InputLabel id="users">Usuarios</InputLabel>
                <Select
                  labelId="users"
                  id="usersSelect"
                  label="Usuario"
                  onChange={handleChange}
                  value={userSelected || ''}
                >
                  {users?.map((user, index) => (
                    <MenuItem key={index} value={user._id}>
                      {user.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <FormControl fullWidth>
              <MobileDatePicker
                label="Data"
                value={value}
                inputFormat="dd/MM/yyyy"
                orientation="portrait"
                onChange={handleChangeDate}
                closeOnSelect={true}
                renderInput={(params) => (
                  <TextField helperText={'Escolha o dia da agenda'} {...params} />
                )}
              />
            </FormControl>
          </Typography>

          {!scheduleMouth && userSelected && (
            <CustomButton
              variant={'contained'}
              sx={{ mt: 2 }}
              onClick={handleOpenMouth}
            >
              Iniciar agenda do mês
            </CustomButton>
          )}
          <Typography variant="h5" component="h5" align="center" mt={2}>
            {day && (
                <>
                {day?.weekday.charAt(0).toUpperCase() + day?.weekday.slice(1)}
                <IconButton color="primary" aria-label="atualizar" onClick={() => refresh()}>
                  <RefreshIcon fontSize={'small'} />
                </IconButton>
                </>
              )
            }
          
          </Typography>
          {!daySchedule && userSelected && (
            <Typography
              variant="h5"
              component="h5"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              Sem agenda para este dia
            </Typography>
          )}
          {daySchedule &&
            userSelected &&
            daySchedule.schedules.map((el, index) => (
                <ScheduleHour
                  fullDate={value!.toFormat('dd/MM/yyyy')}
                  data={el}
                  setReload={setReload}
                  key={index}
                  selectedUser={userSelected}
                />
            ))}
            {daySchedule &&
            userSelected && (
              <Box display="flex" justifyContent="center">
                <CustomButton variant={'contained'} onClick={() => inserirEcaixe(daySchedule._id)}>
                  Inserir encaixe
                </CustomButton>

              </Box>
            )}
        </Container>
      </LocalizationProvider>
    </AsideBar>
  );
};

export default ColaboratorSchedule;
