import styled from 'styled-components';

export const Container = styled.div`
@media only screen and (max-width: 750px) {
   
   margin-right: 4;
   }

@media only screen and (max-width: 450px) {
   
    .button {
        display: flex;
        flex-direction: column;
      width: 100%;
      margin-left: 0;
      }
    }
`;
